import CryptoJS from 'crypto-js';

/**
 * AES加密 ：字符串 key iv  返回base64
 */
export function Encrypt(word, keyStr) {
  const srcs = CryptoJS.enc.Utf8.parse(word);
  const encrypted = CryptoJS.AES.encrypt(srcs, CryptoJS.enc.Utf8.parse(keyStr), {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return { data: CryptoJS.enc.Base64.stringify(encrypted.ciphertext) };
}

/**
 * AES 解密 ：字符串 key 返回base64
 *
 */
export function Decrypt(word, keyStr) {
  if (!word) return;
  const decrypt = CryptoJS.AES.decrypt(word, CryptoJS.enc.Utf8.parse(keyStr), {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });

  const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
  return decryptedStr.toString();
}

export function isEncrypt(str) {
  if (typeof str == 'string') {
    return true;
  }
  return false;
}
