import { v4 as uuidv4 } from 'uuid'


// 获取浏览器类型
export const getBrowserInfo = () => {
  const u = navigator.userAgent,
    p = navigator.platform,
    isChrome = u.match(/Chrome\/([\d.]+)/) || u.match(/CriOS\/([\d.]+)/); // Chrome
  return {
    //移动终端浏览器版本信息
    winPc: p.indexOf("Win") > -1,
    macPc: p.indexOf("Mac") > -1,
    sysLinux: p === "X11" || p.indexOf("Linux") === 0,
    lieBao: u.indexOf("LieBao") > -1, // 猎豹
    opr: u.indexOf("OPR") > -1, // OPR,
    uc: u.indexOf("UCBrowser") > -1, // UC
    mi: u.indexOf("MiuiBrowser") > -1, // 小米
    iosChrome: u.indexOf("CriOS") > -1,
    chrome: isChrome,
    webview:
      (!isChrome && u.match(/(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/)) ||
      u.match(/Android.*(wv|\.0\.0\.0)/),
    iosOpera: u.indexOf("OPiOS") > -1,
    iosUc: u.indexOf("UCBrowser") > -1,
    iosFirefox: u.indexOf("FxiOS") > -1,
    baidu: u.indexOf("baidubrowser") > -1,
    trident: u.indexOf("Trident") > -1, // IE内核
    presto: u.indexOf("Presto") > -1, // opera内核
    webKit: u.indexOf("AppleWebKit") > -1, // 苹果,谷歌内核
    gecko: u.indexOf("Gecko") > -1 && u.indexOf("KHTML") === -1, // 火狐内核
    mobile: !!u.match(/AppleWebKit.*Mobile.*/) || !!u.match(/AppleWebKit/), // 是否为移动终端
    ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // ios终端
    android: u.indexOf("Android") > -1 || u.indexOf("Linux") > -1, // android终端或者uc浏览器
    iPhone: u.indexOf("iPhone") > -1 || u.indexOf("Mac") > -1, // 是否为iPhone或者QQHD浏览器
    iPad: u.indexOf("iPad") > -1, // 是否iPad
    webApp: u.indexOf("Safari") > -1, // 是否web应该程序，没有头部与底部
    language: (navigator?.browserLanguage || navigator.language).toLowerCase(),
  };
};


export function getUrlParamsToJson(searchStr) {
  try {
    const pars = searchStr.match(/\?([^#]+)/)[1]
    const obj = {}
    const arr = pars.split('&')
    for (let i = 0; i < arr.length; i++) {
      const subArr = arr[i].split('=')
      obj[decodeURIComponent(subArr[0])] = decodeURIComponent(subArr[1])
    }
    return obj
  } catch (err) {
    return {}
  }
}

export function saveImg() {
  const canvasImg = document.getElementById('qrCode') // 获取canvas类型的二维码
  let blob = base64ToBlob(canvasImg.toDataURL('image/png'))  // 转码
  const filename = '二维码.png'
  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = filename
  a.dispatchEvent(new MouseEvent('click'))
  window.URL.revokeObjectURL(url)
}

/**
 * base64转blob
 * @param {String} code base64个数数据
 * @return {undefined}
 * @author xxx
 */
const base64ToBlob = (code) => {
  let parts = code.split(';base64,');
  let contentType = parts[0].split(':')[1];
  let raw = window.atob(parts[1]);
  let rawLength = raw.length;
  let uInt8Array = new Uint8Array(rawLength);
  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
  }
  return new window.Blob([uInt8Array], { type: contentType });
}

export function convertMillisecondsToHMS(milliseconds) {
  var totalSeconds = Math.floor(milliseconds / 1000);
  var hours = Math.floor(totalSeconds / 3600);
  var secondsLeft = totalSeconds % 3600;
  var minutes = Math.floor(secondsLeft / 60);
  var seconds = secondsLeft % 60;
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  if (hours === "00") {
    return minutes + ":" + seconds;
  }
  return hours + ":" + minutes + ":" + seconds;
}


export function getLocalKey() {
  switch (process.env.SVC_ENV) {
    case 'production':
      return '5566'
    case 'staging':
      return '5566'
    case 'dev':
      return '5566'
    default:
      return '5566'
  }
}

export const getTraceId = () => {
  let traceId = localStorage.getItem('traceId')
  if (!traceId) {
    const uuid = uuidv4()
    localStorage.setItem('traceId', uuid)
    traceId = uuid
  }
  return traceId
}

export const getBundleId = () => {
  return 'H5'
}

export const ChannelId = {
  "PC": 'A',
  "iOS": 'B',
  "Android": 'C',
  "H5": 'D',
  "other": 'E',
}

//动态获取设备内容
export function getChannelId() {
  const browserInfo = getBrowserInfo()
  if (browserInfo.webview) {
    if (browserInfo.ios) {
      return ChannelId.iOS
    } else if (browserInfo.android) {
      return ChannelId.Android
    } else {
      return ChannelId.other //其他
    }
  } else {
    return ChannelId.H5
  }
}