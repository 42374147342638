import { useEffect, useState } from "react";
import { convertMillisecondsToHMS, getUrlParamsToJson, saveImg } from "../../common/util";
import styles from "./index.module.css";
import Qrcode from 'qrcode.react'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import axios from 'axios';
import { getBundleId, getChannelId, getLocalKey, getTraceId } from "../../common/util"
import { v4 as uuidv4 } from 'uuid'
import toast from "../../common/toast";
import { Decrypt, Encrypt, isEncrypt } from "../../common/AES";
import JSON5 from 'json5';


const map = {};

axios.interceptors.request.use(function (config) {
  const timestamp = new Date().getTime();
  const key = (timestamp + config.headers.TraceId).substr(0, 16);

  map[config.url] = {
    key,
    uuid: config.headers.uuid,
  };

  config.data = Encrypt(
    JSON.stringify(config.data),
    map[config.url].key
  );

  // 加上时间戳
  config.headers.time = timestamp;
  return config;
});

axios.interceptors.response.use(
  function (response) {
    const Encrypt = isEncrypt(response.data);

    if (Encrypt) {
      const key = map?.[response.config.url]?.key;
      const decryptedData = Decrypt(response.data, key);
      const parsedData = JSON5.parse(decryptedData);
      response.data = parsedData;
    }

    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);


const Home = () => {
  const [orderInfo, setOrderInfo] = useState({});
  const orderId = getUrlParamsToJson(window.location.search)?.orderId;
  const downUrl = getUrlParamsToJson(window.location.search)?.downUrl;
  const [countdown, setCountdown] = useState(orderId ? 1500000 : 0);
  const [status, setStatus] = useState(0);

  const qrcodeInfo = {
    ...orderInfo,
    logo: '',
  };

  useEffect(() => {
    if (countdown > 0) {
      setTimeout(() => {
        setCountdown(countdown - 1000);
      }, 1000);
    }
  }, [countdown])

  useEffect(() => {
    if (orderId) {
      axios.post('/v1/api/order', { orderId }, {
        headers: {
          site: getLocalKey(),
          TraceId: getTraceId(),
          uuid: uuidv4(),
          bundleId: getBundleId(),
          channelId: getChannelId(),
          'content-Type': "application/json"
        }
      })
        .then((res) => {
          try {
            res.data = JSON.parse(res.data);
            if (res.data.code === 'C2' && res.data.result) {
              const result = res.data.result
              setOrderInfo(JSON.parse(result))
            } else {
              // toast("无效地址,请联系客服");
            }
          } catch (error) {
          }
          setStatus(1);

        })
        .catch((error) => {
          console.log(error);
        })
    } else {
      setStatus(1);
      // toast("无效地址,请联系客服");
    }
  }, [orderId]);

  return (
    <div className={styles.main}>
      {
        Object.keys(orderInfo).length ? (
          <div className={styles.card}>
            <img
              className={styles.logo}
              src={require("../../assets/imgs/logo.png")}
              // src={orderInfo?.logo}
              alt=""
            />
            <p className={styles.name}>
              {/* {orderInfo?.targetAddr} */}
            </p>
            <p className={styles.amount}>
              {orderInfo?.busiAmount}<i>元</i>
            </p>
            <p>充值金额</p>
            <div className={styles.qrcode}>
              <Qrcode
                id="qrCode"
                value={JSON.stringify(qrcodeInfo)}
              />
            </div>
            <i
              className={styles.saveImg}
              onClick={() => {
                saveImg()
              }}
            >
              保存二维码
            </i>
            <p>请在<i>{convertMillisecondsToHMS(countdown)}</i>之内完成支付</p>
            <p>
              订单编号: <i>{orderId}</i>
              <CopyToClipboard
                text={orderId}
                onCopy={() => { toast("复制成功") }}
              >
                <span>复制</span>
              </CopyToClipboard>
            </p>
            <p onClick={() => {
              // window.open("https://h5.pay99.top")
              window.location.href = `pay99://memrc?orderId=${orderId}`
            }}
            >
              打开99钱包APP支付
            </p>
          </div>
        ) : (
          <div className={styles.card + " " + styles.empty}>
            {status ? <div>无效地址,请联系客服</div> : null}
            <p onClick={() => {
              // window.open("https://h5.pay99.top")
              window.location.href = `pay99://memrc?orderId=${orderId}`
            }}
            >
              打开99钱包APP支付
            </p>
          </div>
        )
      }


      <div className={styles.footer}>
        <p>支付流程</p>
        <p>保存二维码 → 打开99pay → 扫描二维码 → 转账支付</p>
        <div className={styles.download}>
          <div>
            <img src={require("../../assets/imgs/wallet.png")} alt="" />
            <p>安全/快捷/充值/提现 秒到账</p>
          </div>
          <a className={styles.down} href={downUrl}>
            下载99钱包APP
          </a>
        </div>
      </div>
    </div>
  );
}

export default Home;