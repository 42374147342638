import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client';
import styles from "./index.module.css"

function Toast({ msg, destroy }) {
  const [isHiding, setIsHiding] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setIsHiding(true)
    }, 2000);
    setTimeout(() => {
      destroy()
    }, 2400);
  }, [destroy])

  return (
    <div className={styles.msg}>
      <p className={isHiding ? styles.hiding : ""}>
        {msg}
      </p>
    </div>
  )
}

export default function toast(msg) {
  const div = document.createElement('div')
  document.body.appendChild(div)
  const root = ReactDOM.createRoot(div);
  const destroy = () => {
    if (div) {
      root.unmount()
      document.body.removeChild(div)
    }
  }
  root.render(
    <Toast msg={msg} destroy={destroy} />
  );
}